import { NgStyle } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * Account sw-icon
 */
@Component({
  selector: 'account-sw-icon',
  templateUrl: './sw-icon.component.html',
  styleUrl: './sw-icon.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgStyle],
})
export class SwIconComponent {
  /**
   * Name of the icon that should be displayed.
   * Full list of icons available in `/Components/Account2/src/app/shared/components/sw-icon/sw-icon.component.less`
   */
  @Input() icon = 'desktop';

  /**
   * Color of the icon.
   */
  @Input() color: string;

  /**
   * Icon font size in Pixel. Default is 'inherit'.
   */
  @Input() size = 'inherit';

  /**
   * Icon set variant. Either 'solid' or 'regular'.
   */
  @Input() variant = 'regular';
}
