import { NgIf, NgStyle } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SwIconComponent } from '../sw-icon/sw-icon.component';
import { EmptyStateStyle } from './empty-state.model';

@Component({
  selector: 'account-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrl: './empty-state.component.less',
  standalone: true,
  imports: [NgIf, NgStyle, SwIconComponent, TranslateModule],
})
export class EmptyStateComponent {
  @Input()
  set icon(value: string) {
    if (!value || value === '') {
      value = 'database';
    }

    this._icon = value;
  }
  get icon(): string {
    return this._icon;
  }
  @Input() iconStatus: EmptyStateStyle = 'info';
  @Input()
  set heading(value: string) {
    if (!value || value === '') {
      value = 'COMMON.TABLE.EMPTY_STATE.HEADING';
    }

    this._heading = value;
  }
  get heading(): string {
    return this._heading;
  }
  @Input() subheading: string;
  @Input() textAsHTML = false;
  @Input() illustrationUrl: string = null;
  @HostBinding('class.es--inline') @Input() inline = false;

  private _icon: string;
  private _heading: string;
}
